<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<div>
									<p>Sometimes you may experience buffering due to insufficient bandwidth, and sometimes a bug in the system.</p>
									<p>
										To experience the {{ $store.state.brandInfo.displayName }} as smooth as possible don't use Wifi nor any type of extended system, for best
										result use Wire Ethernet Cable only to your device.
									</p>
								</div>
								<ul class="space-y-5 ml-10 mt-5">
									<li>1. Log out of the app</li>
									<li>2. If possible on your device (eg Fire stick, Android TV) clear the app cache and storage.</li>
									<li>3. Turn off your device</li>
									<li>4. Turn off your router and leave for one minute</li>
									<li>5. Turn back on, Login & try to load the content.</li>
								</ul>
								<p>If the problem persists please do the following:</p>
								<div class="font-semibold">
									<p>When running speed tests, for more accurate and realistic results, run the tests on the device you're using to stream.</p>
									<p>i.e if you're using Android Box, you should open its' browser and check there, not on your phone.</p>
								</div>
								<div>
									<p>Please enter our speed test tool. And test it on the device you're using to stream!!</p>
									<p><a :href="$store.state.brandInfo.speedTestLink" class="hover:text-blue-500">$store.state.brandInfo.speedTestLink</a></p>
								</div>
								<div class="space-y-2">
									<p>Our speedtest is divided into 3 sections:</p>
									<img src="./../../../assets/images/mini-icon/speedTest.png" class="w-8/12" alt="" />
									<p>
										<span class="font-semibold text-blue-500">First section</span>: Info about your connection. Your IP address, your Internet Service Provider,
										and country.
									</p>
								</div>
								<div>
									<p>
										<span class="font-semibold text-red-500">Second section</span>: This section is the actual speed tests, which is divided into 3 main tests.
									</p>
									<p>First test - CloudFront is a speedtest from a CDN of amazon (to emulate local speedtest - 1 connection)</p>
									<p>Second test -{{ $store.state.brandInfo.displayName }}Live is the server you are playing live streaming from, based on your location.</p>
									<p>
										Third test -{{ $store.state.brandInfo.displayName }}VOD is the server you are playing VOD content from, again this is based on your
										location.
									</p>
									<p>
										*The servers you're testing with, are the servers you're streaming from, this will show an accurate picture on how your network can handle
										{{ $store.state.brandInfo.displayName }} service.
									</p>
								</div>
								<div>
									<p>
										<span class="font-semibold text-green-400">Third section</span>: The results, here we will post our recommendation for you based on the
										speed results you received.
									</p>
									<p class="font-semibold">*Speed-test of{{ $store.state.brandInfo.displayName }}LIVE & VOD are limited to 50Mbit.</p>
								</div>
								<p class="font-semibold">Please be aware that you must get minimum of 20Mbit bandwidth in order to experience our service at it's best.</p>
								<div class="font-semibold text-xs space-y-1">
									<p>
										MPORTANT:
										<span class="bg-yellow-300">There are certain situations where you will receive 20Mbit/s or higher and still experience issues.</span>
									</p>
									<p><span class="bg-yellow-300">These situations will require usage of our recommended VPNs. Please see below for more information.</span></p>
								</div>
								<div>
									<p>Please use a VPN as a resolution to any buffering/freezing that you may experience.</p>
									<p>
										Internet service providers are throttling and slowing down connections to our servers which leads to buffering and freezing issues you may
										experience. This isn’t something that can be fixed from our end and can happen at anytime even if it worked perfectly for you without a VPN
										in the past. You can rest assured that it is our mission to provide you with the best service available and we continuously keep our servers
										in tip top shape to ensure all our customers receive only the best
									</p>
								</div>
								<hr />
								<div class="space-y-5">
									<p class="font-semibold text-lg">Commonly Asked Questions:</p>
									<div>
										<p class="font-semibold">Q: What is a VPN?</p>
										<p>
											A: A VPN disguises your internet network from your internet provider in order to stop them from seeing what you use your internet for
											and how you choose to use your internet.
										</p>
									</div>
									<div>
										<p class="font-semibold">Q: I called my internet provider and they told me that they do not throttle my internet or slow down my speeds?</p>
										<p>
											A: we can assure you that after providing support for many customers we can determine that internet providers do not like to advertise
											or admit to their customers that they throttle their speeds. After connecting a VPN the issue almost magically disappears which clearly
											displays that the internet provider is throttling the speed.
										</p>
									</div>
									<div>
										<p class="font-semibold">Q:But my HULU, Amazon, Netflix accounts work just fine without buffering?</p>
										<p>
											A: This is specific to our servers and a VPN is the only solution to stop your internet provider from slowing down your speeds when
											using our service.
										</p>
									</div>
									<hr />
									<div class="space-y-2">
										<p class="font-semibold">Q: What VPN’s do you recommend?</p>
										<p>A: Our Recommended VPN providers are</p>
										<div class="font-semibold">
											<p>-Ipvanish</p>
											<p>-Nord VPN</p>
											<p>-Surkshark VPN</p>
										</div>
									</div>
									<hr />
									<div class="space-y-1">
										<p class="font-semibold">Q: How can I install a VPN?</p>
										<p>A: Visit our recommended VPN provider’s website for detailed installation instructions guides for all of your devices.</p>
										<p>Additional how-to installation videos are available on YouTube.</p>
									</div>
									<hr />
									<div class="space-y-1">
										<p class="font-semibold">Q: I’m already using a VPN and still experiencing issues.</p>
										<p>
											A: Not all VPN providers are created equal. Please choose a VPN from our recommended VPN providers listed above. If you are already
											using one of our recommended VPN providers and still experiencing buffering, please try changing the server location within the VPN.
										</p>
										<p>Suggested VPN server locations are</p>
										<ul>
											<li>-USA</li>
											<li>-Canada</li>
											<li>-Denmark</li>
											<li>-Sweden</li>
											<li>-Switzerland</li>
											<li>-Norway</li>
											<li>-Latvia</li>
											<li>-Finland</li>
										</ul>
										<p>You will need to test multiple server locations in order to find the best one for your location.</p>
										<p>See your VPN providers website for information on how to switch locations on the VPN.</p>
									</div>
									<div>
										<p>How to run speedtest ({{ $store.state.brandInfo.appName }} app)</p>
										<a :href="`${$store.state.helpURL}/en/support/solutions/articles/48001156326`" class="font-semibold hover:text-blue-500"
											>How to run Speedtest</a
										>
									</div>
									<p>
										If you're getting speeds higher than 20Mbit, tried vpn but still having difficulties, please take a screenshot of the the whole screen of
										our speedtest and contact us with the screenshot, at
										<a :href="$store.state.brandInfo.supportEmail" class="hover:text-blue-500">{{ $store.state.brandInfo.supportEmail }}</a
										>.
									</p>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LiveTvVodBufferFreezesOrNotWorking',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
